import i18n from '@/plugins/i18n'

export const reports=[
    {
        value: '1',
        text: i18n.t('number_of_courses_enrolled_by_student'),
        permission:'studentreport_studentsyearlycourselistreport'
    },
    {
        value: '2',
        text: i18n.t('grade_distribution_report'),
        permission: 'studentreport_gradedistributionlistreport'
    },
    {
        value: '3',
        text: i18n.t('student_report'),
        permission: 'studentslot_studentslotsexcelexport'
    },
    {
        value: '4',
        text: i18n.t('ie_reporting'),
        permission: 'studentreport_iereporting'
    },
    {
        value: '5',
        text: i18n.t('curriculum_credits_report'),
        permission: 'studentreport_studentcurriculumcreditsreport'
    },


]
